export const faqList: {
  id: string;
  title: string;
  description: string;
}[] = [
  {
    id: "01",
    title: "Jak mogę zarejestrować się jako mentor lub mentee?",
    description:
        "Aby zarejestrować się jako mentor, kliknij opcję „Zostań mentorem” na stronie głównej. Aby zarejestrować się jako mentee," +
        " kliknij „Mój profil” w prawym górnym rogu strony. Postępuj zgodnie z instrukcjami na ekranie, aby zakończyć proces rejestracji.",
  },
  {
    id: "02",
    title: "Jak mogę zarezerwować sesję z mentorem?",
    description:
        "Aby zarezerwować sesję, zaloguj się na swoje konto, przejdź do sekcji „Znajdź mentora”, " +
        "przeszukaj dostępnych mentorów i wybierz osobę, z którą chcesz się spotkać. Wybierz dogodny termin i potwierdź rezerwację. " +
        "Możesz zapisać się na pojedynczą sesję jako gość, jednak aby zapisać się na mentoring, musisz posiadać założone konto.",
  },
  {
    id: "03",
    title: "Czy mogę zmienić swoje hasło?",
    description:
        "Tak, aby zmienić hasło, zaloguj się na swoje konto, a następnie przejdź do sekcji „Ustawienia”. Wybierz opcję „Zmień hasło” i postępuj zgodnie z instrukcjami, aby ustawić nowe hasło.",
  },
  {
    id: "04",
    title: "Czy mogę anulować sesję?",
    description:
        "Tak, możesz anulować sesję do 24 godzin przed jej planowanym rozpoczęciem. Wejdź w szczegóły rezerwacji i wybierz opcję „Anuluj sesję”.",
  },
  {
    id: "05",
    title: "Jak mogę otrzymać fakturę za sesje?",
    description:
        "Faktury za sesje możesz pobrać w sekcji „Płatno”. Tam znajdziesz wszystkie informacje na temat dokonanych płatności oraz opcję pobrania faktur.",
  },
  {
    id: "06",
    title: "Jak mogę zostać mentorem?",
    description:
        "Aby zostać mentorem, przejdź na stronę „Zostań mentorem”, wypełnij formularz rejestracyjny i poczekaj na weryfikację Twojego profilu. Po zatwierdzeniu będziesz mógł oferować swoje usługi jako mentor.",
  },
  {
    id: "07",
    title: "Czy mogę zarezerwować sesję bez zakładania konta?",
    description:
        "Tak, możesz zarezerwować jednorazową sesję jako gość, jednak zalecamy założenie konta, aby móc śledzić swoje rezerwacje i otrzymywać powiadomienia o sesjach. Zalogowanie się daje również dostęp do programów mentoringowych.",
  },
  {
    id: "08",
    title: "Jak wygląda proces płatności za sesję?",
    description:
        "Płatności są realizowane poprzez nasz zintegrowany system płatności Stripe. Po zarezerwowaniu sesji zostaniesz przekierowany na stronę płatności, gdzie dokonasz płatności za pomocą dostępnych metod.",
  },
  {
    id: "09",
    title: "Czy moje płatności trafiają bezpośrednio do mentora?",
    description:
        "Tak, wszystkie płatności za sesje są realizowane za pomocą Stripe, a środki trafiają bezpośrednio na subkonto mentora w systemie Stripe. Nasza platforma nie pośredniczy w przechowywaniu środków.",
  },
  {
    id: "10",
    title: "Jak mogę ocenić mojego mentora po sesji?",
    description:
        "Po zakończeniu sesji będziesz mógł ocenić swojego mentora w sekcji „Historia spotkań”. Wystawienie opinii jest opcjonalne, ale pomoże innym użytkownikom w wyborze mentora.",
  },
  {
    id: "11",
    title: "Czy mogę usunąć swoje konto?",
    description:
        "Tak, możesz usunąć swoje konto w dowolnym momencie. Przejdź do ustawień swojego profilu, a następnie wybierz opcję „Usuń konto”. Pamiętaj, że usunięcie konta jest nieodwracalne. Mentorzy mogą usunąć konto dopiero po odbyciu wszystkich sesji i rozliczeniu się z mentee.",
  },
];
