import React from 'react';

const Group = () => {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12 2.66675C8.50669 2.66675 5.66669 5.50675 5.66669 9.00008C5.66669 12.4267 8.34669 15.2001 11.84 15.3201C11.9467 15.3067 12.0534 15.3067 12.1334 15.3201C12.16 15.3201 12.1734 15.3201 12.2 15.3201C12.2134 15.3201 12.2134 15.3201 12.2267 15.3201C15.64 15.2001 18.32 12.4267 18.3334 9.00008C18.3334 5.50675 15.4934 2.66675 12 2.66675Z'
				fill='#252B61'
			/>
			<path
				d='M18.7733 18.8666C15.0533 16.3866 8.98668 16.3866 5.24001 18.8666C3.54668 19.9999 2.61334 21.5333 2.61334 23.1733C2.61334 24.8133 3.54668 26.3333 5.22668 27.4533C7.09334 28.7066 9.54668 29.3333 12 29.3333C14.4533 29.3333 16.9067 28.7066 18.7733 27.4533C20.4533 26.3199 21.3867 24.7999 21.3867 23.1466C21.3733 21.5066 20.4533 19.9866 18.7733 18.8666Z'
				fill='#252B61'
			/>
			<path
				d='M26.6534 9.78664C26.8667 12.3733 25.0267 14.64 22.48 14.9466C22.4667 14.9466 22.4667 14.9466 22.4534 14.9466H22.4134C22.3334 14.9466 22.2534 14.9466 22.1867 14.9733C20.8934 15.04 19.7067 14.6266 18.8134 13.8666C20.1867 12.64 20.9734 10.8 20.8134 8.79997C20.72 7.71997 20.3467 6.7333 19.7867 5.8933C20.2934 5.63997 20.88 5.47997 21.48 5.42664C24.0934 5.19997 26.4267 7.14664 26.6534 9.78664Z'
				fill='#252B61'
			/>
			<path
				d='M29.32 22.1201C29.2133 23.4135 28.3867 24.5335 27 25.2935C25.6667 26.0268 23.9867 26.3735 22.32 26.3335C23.28 25.4668 23.84 24.3868 23.9467 23.2401C24.08 21.5868 23.2933 20.0001 21.72 18.7335C20.8267 18.0268 19.7867 17.4668 18.6533 17.0535C21.6 16.2001 25.3067 16.7735 27.5867 18.6135C28.8133 19.6001 29.44 20.8401 29.32 22.1201Z'
				fill='#252B61'
			/>
		</svg>
	);
};

export default Group;
