import React from 'react';

const Home = () => {
	return (
		<svg
			width='20'
			height='21'
			viewBox='0 0 20 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10 15.5V13'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.39172 2.85004L2.61672 7.47504C1.96672 7.9917 1.55006 9.08337 1.69172 9.90004L2.80006 16.5334C3.00006 17.7167 4.13339 18.675 5.33339 18.675H14.6667C15.8584 18.675 17.0001 17.7084 17.2001 16.5334L18.3084 9.90004C18.4417 9.08337 18.0251 7.9917 17.3834 7.47504L11.6084 2.85837C10.7167 2.1417 9.27506 2.1417 8.39172 2.85004Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Home;
