import React from "react";

export const SendArrow = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5297 13.5578L16.9601 9.26668C18.2096 5.51803 18.8344 3.6437 17.845 2.65431C16.8556 1.66492 14.9813 2.28969 11.2327 3.53925L6.94155 4.96962C3.91602 5.97813 2.40326 6.48238 1.97337 7.22183C1.56442 7.92529 1.56442 8.79409 1.97337 9.49754C2.40326 10.237 3.91602 10.7413 6.94154 11.7498C7.42734 11.9117 7.67024 11.9927 7.87327 12.1286C8.07003 12.2603 8.23905 12.4293 8.37077 12.6261C8.50668 12.8291 8.58765 13.072 8.74958 13.5578C9.75809 16.5833 10.2623 18.0961 11.0018 18.526C11.7052 18.9349 12.5741 18.9349 13.2775 18.526C14.017 18.0961 14.5212 16.5833 15.5297 13.5578Z"
        stroke="white"
      />
      <path
        d="M13.4218 7.78432C13.6181 7.59014 13.6199 7.27357 13.4257 7.07723C13.2315 6.88088 12.9149 6.87913 12.7186 7.07331L13.4218 7.78432ZM8.79766 12.3575L13.4218 7.78432L12.7186 7.07331L8.09449 11.6464L8.79766 12.3575Z"
        fill="white"
      />
    </svg>
  );
};

export default SendArrow;
