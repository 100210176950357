import React from 'react';

const Timer = () => {
	return (
		<svg
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.00016 13.6602C10.6822 13.6602 13.6668 10.6756 13.6668 6.99357C13.6668 3.31157 10.6822 0.326904 7.00016 0.326904C3.31816 0.326904 0.333496 3.31157 0.333496 6.99357C0.333496 10.6756 3.31816 13.6602 7.00016 13.6602ZM7.50016 4.3269C7.50016 4.1943 7.44748 4.06712 7.35372 3.97335C7.25995 3.87958 7.13277 3.8269 7.00016 3.8269C6.86755 3.8269 6.74038 3.87958 6.64661 3.97335C6.55284 4.06712 6.50016 4.1943 6.50016 4.3269V6.99357C6.50016 7.12624 6.55283 7.25357 6.64683 7.3469L8.3135 9.01357C8.35927 9.06269 8.41447 9.1021 8.4758 9.12942C8.53714 9.15675 8.60335 9.17145 8.67048 9.17263C8.73762 9.17382 8.8043 9.16147 8.86656 9.13632C8.92882 9.11117 8.98538 9.07374 9.03286 9.02626C9.08033 8.97878 9.11776 8.92223 9.14291 8.85997C9.16806 8.79771 9.18041 8.73102 9.17922 8.66389C9.17804 8.59675 9.16335 8.53054 9.13602 8.46921C9.10869 8.40788 9.06929 8.35268 9.02016 8.3069L7.50016 6.7869V4.3269Z'
				fill='#8E9ABB'
			/>
		</svg>
	);
};

export default Timer;
