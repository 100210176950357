import React from 'react';

const Person = () => {
	return (
		<svg
			width='10'
			height='13'
			viewBox='0 0 10 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.00016 5.66671C6.47292 5.66671 7.66683 4.4728 7.66683 3.00004C7.66683 1.52728 6.47292 0.333374 5.00016 0.333374C3.5274 0.333374 2.3335 1.52728 2.3335 3.00004C2.3335 4.4728 3.5274 5.66671 5.00016 5.66671Z'
				fill='#8E9ABB'
			/>
			<path
				d='M5.00016 13C7.57749 13 9.66683 11.8061 9.66683 10.3334C9.66683 8.86061 7.57749 7.66671 5.00016 7.66671C2.42283 7.66671 0.333496 8.86061 0.333496 10.3334C0.333496 11.8061 2.42283 13 5.00016 13Z'
				fill='#8E9ABB'
			/>
		</svg>
	);
};

export default Person;
