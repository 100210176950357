import React from 'react';

type Props = {
	color?: string,
}

const Pencil = ({ color = '#252B61' }: Props) => {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.9666 3.39884L12.739 2.62644C14.0187 1.34669 16.0936 1.34669 17.3734 2.62644C18.6531 3.90619 18.6531 5.98109 17.3734 7.26084L16.601 8.03324M11.9666 3.39884C11.9666 3.39884 12.0631 5.04019 13.5114 6.48844C14.9596 7.93669 16.601 8.03324 16.601 8.03324M11.9666 3.39884L4.86552 10.4999C4.38456 10.9808 4.14407 11.2213 3.93725 11.4865C3.69328 11.7993 3.48412 12.1377 3.31346 12.4958C3.16878 12.7994 3.06123 13.122 2.84614 13.7673L1.93468 16.5017M16.601 8.03324L9.49992 15.1343C9.01895 15.6152 8.77847 15.8557 8.51331 16.0625C8.20052 16.3065 7.86208 16.5157 7.50398 16.6863C7.20042 16.831 6.87777 16.9386 6.23249 17.1537L3.4981 18.0651M3.4981 18.0651L2.8297 18.2879C2.51215 18.3938 2.16205 18.3111 1.92536 18.0744C1.68867 17.8377 1.60603 17.4876 1.71188 17.1701L1.93468 16.5017M3.4981 18.0651L1.93468 16.5017'
				stroke={color}
				strokeWidth='1.5'
			/>
		</svg>
	);
};

export default Pencil;
