import React from 'react';

const Raports = () => {
	return (
		<svg
			width='72'
			height='72'
			viewBox='0 0 72 72'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<circle cx='36' cy='36' r='36' fill='#F0DA69' fillOpacity='0.15' />
			<g clipPath='url(#clip0_161_380)'>
				<path
					d='M18.2466 40.4951H22.7121C23.1601 40.4951 23.5237 40.8649 23.5237 41.3204V48.1743C23.5237 48.6298 23.1601 48.9996 22.7121 48.9996H18.2462C17.7983 48.9996 17.4346 48.6298 17.4346 48.1743V41.3208C17.4346 40.8651 17.7984 40.4951 18.2466 40.4951Z'
					fill='#F0DA69'
				/>
				<path
					d='M28.9276 34.5986H33.3927C33.8407 34.5986 34.2044 34.9684 34.2044 35.4239V48.1751C34.2044 48.6306 33.8407 49.0004 33.3927 49.0004H28.9269C28.4789 49.0004 28.1152 48.6306 28.1152 48.1751V35.4247C28.1152 34.9688 28.4792 34.5986 28.9276 34.5986Z'
					fill='#F0DA69'
				/>
				<path
					d='M39.6079 40.4951H44.0738C44.5217 40.4951 44.8854 40.8649 44.8854 41.3204V48.1743C44.8854 48.6298 44.5217 48.9996 44.0738 48.9996H39.6075C39.1596 48.9996 38.7959 48.6298 38.7959 48.1743V41.3208C38.7959 40.8651 39.1598 40.4951 39.6079 40.4951Z'
					fill='#F0DA69'
				/>
				<path
					d='M50.288 34.5986H54.7531C55.2011 34.5986 55.5647 34.9684 55.5647 35.4239V48.1751C55.5647 48.6306 55.2011 49.0004 54.7531 49.0004H50.2876C49.8394 49.0004 49.4756 48.6304 49.4756 48.1747V35.4247C49.4756 34.9688 49.8396 34.5986 50.288 34.5986Z'
					fill='#F0DA69'
				/>
				<path
					d='M20.4796 37.054C22.4012 37.054 23.9592 35.4706 23.9592 33.5155C23.9592 33.2372 23.9195 32.9693 23.8597 32.7089L29.0393 29.3256C29.6278 29.789 30.3576 30.0762 31.1601 30.0762C31.9622 30.0762 32.6917 29.789 33.2806 29.3256L38.4601 32.7089C38.4 32.9693 38.3607 33.2372 38.3607 33.5155C38.3607 35.4706 39.9187 37.054 41.8403 37.054C43.7619 37.054 45.3198 35.4706 45.3198 33.5155C45.3198 33.2372 45.2806 32.9693 45.2204 32.7089L50.4 29.3256C50.9885 29.789 51.7183 30.0762 52.5204 30.0762C54.4424 30.0762 56 28.4921 56 26.5377C56 24.5842 54.4424 23 52.5204 23C50.5988 23 49.0409 24.5842 49.0409 26.5377C49.0409 26.8168 49.0805 27.0847 49.1403 27.3451L43.9611 30.7292C43.3722 30.265 42.6427 29.9778 41.8403 29.9778C41.0378 29.9778 40.3083 30.265 39.7194 30.7292L34.5403 27.3451C34.6001 27.0847 34.6397 26.8168 34.6397 26.5377C34.6397 24.5842 33.0817 23 31.1601 23C29.2381 23 27.6805 24.5842 27.6805 26.5377C27.6805 26.8168 27.7198 27.0847 27.78 27.3451L22.6004 30.7292C22.0119 30.265 21.282 29.9778 20.4796 29.9778C18.558 29.9778 17 31.5619 17 33.5155C17 35.4706 18.558 37.054 20.4796 37.054Z'
					fill='#F0DA69'
				/>
			</g>
			<defs>
				<clipPath id='clip0_161_380'>
					<rect width='39' height='26' fill='white' transform='translate(17 23)' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Raports;
