import React from 'react';

const Help = () => {
	return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1665 15.8588H10.8332L7.12483 18.3254C6.57483 18.6921 5.83317 18.3004 5.83317 17.6338V15.8588C3.33317 15.8588 1.6665 14.1921 1.6665 11.6921V6.69206C1.6665 4.19206 3.33317 2.52539 5.83317 2.52539H14.1665C16.6665 2.52539 18.3332 4.19206 18.3332 6.69206V11.6921C18.3332 14.1921 16.6665 15.8588 14.1665 15.8588Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.9668V9.79183C10 9.22516 10.35 8.92515 10.7 8.68348C11.0417 8.45015 11.3833 8.15016 11.3833 7.60016C11.3833 6.83349 10.7667 6.2168 10 6.2168C9.23334 6.2168 8.6167 6.83349 8.6167 7.60016"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99641 11.9577H10.0039"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Help;
