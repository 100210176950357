import React from 'react';

const Person = () => {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M22.1667 9.33341C22.1667 12.7392 19.4058 15.5001 16 15.5001C12.5943 15.5001 9.83337 12.7392 9.83337 9.33341C9.83337 5.92766 12.5943 3.16675 16 3.16675C19.4058 3.16675 22.1667 5.92766 22.1667 9.33341Z'
				fill='#FF6A3D'
				stroke='#FF6A3D'
			/>
			<path
				d='M16 19.3333C9.32 19.3333 3.88 23.8133 3.88 29.3333C3.88 29.7066 4.17334 29.9999 4.54667 29.9999H27.4533C27.8267 29.9999 28.12 29.7066 28.12 29.3333C28.12 23.8133 22.68 19.3333 16 19.3333Z'
				fill='#FF6A3D'
			/>
		</svg>
	);
};

export default Person;
