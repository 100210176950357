import React from 'react';

const Doc = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='18'
			viewBox='0 0 16 18'
			fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.0967 13.1447H5.08008C4.73508 13.1447 4.45508 12.8647 4.45508 12.5197C4.45508 12.1747 4.73508 11.8947 5.08008 11.8947H11.0967C11.4417 11.8947 11.7217 12.1747 11.7217 12.5197C11.7217 12.8647 11.4417 13.1447 11.0967 13.1447Z'
				fill='#F2F2F2'
				className='on-hover'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.0967 9.65601H5.08008C4.73508 9.65601 4.45508 9.37601 4.45508 9.03101C4.45508 8.68601 4.73508 8.40601 5.08008 8.40601H11.0967C11.4417 8.40601 11.7217 8.68601 11.7217 9.03101C11.7217 9.37601 11.4417 9.65601 11.0967 9.65601Z'
				fill='#F2F2F2'
				className='on-hover'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.37494 6.17535H5.0791C4.7341 6.17535 4.4541 5.89535 4.4541 5.55035C4.4541 5.20535 4.7341 4.92535 5.0791 4.92535H7.37494C7.71994 4.92535 7.99994 5.20535 7.99994 5.55035C7.99994 5.89535 7.71994 6.17535 7.37494 6.17535Z'
				fill='#F2F2F2'
				className='on-hover'
			/>
			<mask
				id='mask0_547_136'
				style={{maskType: 'luminance'}}
				maskUnits='userSpaceOnUse'
				x='0'
				y='0'
				width='16'
				height='18'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0.5 0.66684H15.6372V17.2583H0.5V0.66684Z'
					fill='white'
				/>
			</mask>
			<g mask='url(#mask0_547_136)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M11.2575 1.91669L4.85 1.92002C2.91 1.93169 1.75 3.13169 1.75 5.13085V12.7942C1.75 14.8067 2.92083 16.0084 4.88 16.0084L11.2875 16.0059C13.2275 15.9942 14.3875 14.7925 14.3875 12.7942V5.13085C14.3875 3.11835 13.2175 1.91669 11.2575 1.91669ZM4.88083 17.2584C2.26083 17.2584 0.5 15.4642 0.5 12.7942V5.13085C0.5 2.43669 2.20583 0.685854 4.84583 0.67002L11.2567 0.666687H11.2575C13.8775 0.666687 15.6375 2.46085 15.6375 5.13085V12.7942C15.6375 15.4875 13.9317 17.2392 11.2917 17.2559L4.88083 17.2584Z'
					fill='#F2F2F2'
					className='on-hover'
				/>
			</g>
		</svg>
	);
};

export default Doc;
