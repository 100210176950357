import React from 'react';

const Logout = () => {
	return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4165 6.79922C7.67484 3.79922 9.2165 2.57422 12.5915 2.57422H12.6998C16.4248 2.57422 17.9165 4.06589 17.9165 7.79089V13.2242C17.9165 16.9492 16.4248 18.4409 12.6998 18.4409H12.5915C9.2415 18.4409 7.69984 17.2326 7.42484 14.2826"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4999 10.5H3.0166"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.87516 7.70898L2.0835 10.5007L4.87516 13.2923"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logout;
