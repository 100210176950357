import React from "react";

export const Free = ({ className }: { className?: string }) => {
  return (
    <svg
      width="57"
      height="34"
      viewBox="0 0 57 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="2" y="2" width="53" height="30" rx="14" fill="#80CCAB" />
      <rect
        x="2"
        y="2"
        width="53"
        height="30"
        rx="14"
        stroke="#EDF8F3"
        strokeWidth="4"
      />
      <path
        d="M14.808 16.464H18.996V17.784H14.808V16.464ZM14.94 21H13.38V12.6H19.512V13.908H14.94V21ZM21.0206 21V12.6H24.4766C25.2206 12.6 25.8566 12.72 26.3846 12.96C26.9206 13.2 27.3326 13.544 27.6206 13.992C27.9086 14.44 28.0526 14.972 28.0526 15.588C28.0526 16.204 27.9086 16.736 27.6206 17.184C27.3326 17.624 26.9206 17.964 26.3846 18.204C25.8566 18.436 25.2206 18.552 24.4766 18.552H21.8846L22.5806 17.844V21H21.0206ZM26.5166 21L24.3926 17.952H26.0606L28.1966 21H26.5166ZM22.5806 18.012L21.8846 17.268H24.4046C25.0926 17.268 25.6086 17.12 25.9526 16.824C26.3046 16.528 26.4806 16.116 26.4806 15.588C26.4806 15.052 26.3046 14.64 25.9526 14.352C25.6086 14.064 25.0926 13.92 24.4046 13.92H21.8846L22.5806 13.152V18.012ZM31.2379 16.092H35.4139V17.376H31.2379V16.092ZM31.3579 19.692H36.0979V21H29.7979V12.6H35.9299V13.908H31.3579V19.692ZM39.277 16.092H43.453V17.376H39.277V16.092ZM39.397 19.692H44.137V21H37.837V12.6H43.969V13.908H39.397V19.692Z"
        fill="white"
      />
    </svg>
  );
};
