import React from 'react';

const Minus = () => {
	return (
		<svg
			width='12'
			height='2'
			viewBox='0 0 12 2'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1 1H11'
				stroke='#E2E9FC'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Minus;
