import React from "react";

const CreateSchedules = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6665 10.6673V12.084C16.6665 14.709 15.1665 15.834 12.9165 15.834H5.4165C3.1665 15.834 1.6665 14.709 1.6665 12.084V7.58398C1.6665 4.95898 3.1665 3.83398 5.4165 3.83398H7.6665C7.55817 4.15065 7.49984 4.50065 7.49984 4.87565V8.12567C7.49984 8.934 7.7665 9.61733 8.2415 10.0923C8.7165 10.5673 9.39984 10.834 10.2082 10.834V11.9924C10.2082 12.4174 10.6915 12.6756 11.0498 12.4423L13.4582 10.834H15.6248C15.9998 10.834 16.3498 10.7756 16.6665 10.6673Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 4.87435V8.12437C18.3333 9.36604 17.7 10.2993 16.6667 10.666C16.35 10.7743 16 10.8327 15.625 10.8327H13.4583L11.05 12.441C10.6917 12.6743 10.2083 12.416 10.2083 11.991V10.8327C9.4 10.8327 8.71667 10.566 8.24167 10.091C7.76667 9.61602 7.5 8.9327 7.5 8.12437V4.87435C7.5 4.49935 7.55833 4.14935 7.66667 3.83268C8.03333 2.79935 8.96667 2.16602 10.2083 2.16602H15.625C17.25 2.16602 18.3333 3.24935 18.3333 4.87435Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1665 18.834H12.1665"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 15.834V18.834"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4129 6.54167H15.4204"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0799 6.54167H13.0874"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7459 6.54167H10.7534"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreateSchedules;
