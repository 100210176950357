import React from 'react';

const Dots = () => {
	return (
		<svg
			width='4'
			height='16'
			viewBox='0 0 4 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.00016 9.66667C2.92064 9.66667 3.66683 8.92047 3.66683 8C3.66683 7.07952 2.92064 6.33333 2.00016 6.33333C1.07969 6.33333 0.333496 7.07952 0.333496 8C0.333496 8.92047 1.07969 9.66667 2.00016 9.66667Z'
				fill='#56658F'
			/>
			<path
				d='M2.00016 3.83333C2.92064 3.83333 3.66683 3.08714 3.66683 2.16667C3.66683 1.24619 2.92064 0.5 2.00016 0.5C1.07969 0.5 0.333496 1.24619 0.333496 2.16667C0.333496 3.08714 1.07969 3.83333 2.00016 3.83333Z'
				fill='#56658F'
			/>
			<path
				d='M2.00016 15.5C2.92064 15.5 3.66683 14.7538 3.66683 13.8333C3.66683 12.9129 2.92064 12.1667 2.00016 12.1667C1.07969 12.1667 0.333496 12.9129 0.333496 13.8333C0.333496 14.7538 1.07969 15.5 2.00016 15.5Z'
				fill='#56658F'
			/>
		</svg>
	);
};

export default Dots;
