import React from 'react';

const Find = () => {
	return (
		<svg
			width='72'
			height='72'
			viewBox='0 0 72 72'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<circle cx='36' cy='36' r='36' fill='#64A1F2' fillOpacity='0.15' />
			<g clipPath='url(#clip0_172_127)'>
				<path
					d='M19.42 23.4118H49.6528V32.303C50.524 32.4317 51.3307 32.7211 52.0727 33.1231V22.2059C52.0727 21.5306 51.5406 21 50.8628 21H18.21C17.5483 21 17 21.5306 17 22.2059V49.6838C17 50.3591 17.5483 50.8896 18.21 50.8896H38.9083L39.1829 48.6387C39.1829 48.5904 39.1986 48.5262 39.2152 48.4779H19.42V23.4118Z'
					fill='#64A1F2'
				/>
				<path
					d='M48.5547 44.3722C51.2539 44.3722 53.4419 42.1921 53.4419 39.5027C53.4419 36.8138 51.2539 34.6338 48.5547 34.6338C45.8579 34.6338 43.6699 36.8138 43.6699 39.5027C43.6699 42.1921 45.8579 44.3722 48.5547 44.3722Z'
					fill='#64A1F2'
				/>
				<path
					d='M55.521 48.9282C55.3292 47.318 54.4044 45.9582 53.1066 45.1538C51.8541 46.1578 50.2845 46.7843 48.5558 46.7843C46.8271 46.7843 45.2579 46.1576 44.0054 45.1533C42.7088 45.9571 41.7847 47.3157 41.5906 48.9236L41.1218 52.8039C41.0332 53.5395 41.513 54.2398 42.2416 54.3863C44.2389 54.7876 46.358 55.0001 48.5554 55.0001C50.7528 55.0001 52.8711 54.7875 54.8692 54.386C55.5982 54.2396 56.0783 53.5392 55.9893 52.8036L55.521 48.9282Z'
					fill='#64A1F2'
				/>
				<path
					d='M46.0271 29.4315C46.0271 28.7657 45.4855 28.2256 44.8171 28.2256H32.3897C31.7213 28.2256 31.1797 28.7657 31.1797 29.4315C31.1797 30.0972 31.7213 30.6373 32.3897 30.6373H44.8171C45.4855 30.6373 46.0271 30.0972 46.0271 29.4315Z'
					fill='#64A1F2'
				/>
				<path
					d='M39.5845 34.7383H32.3897C31.7213 34.7383 31.1797 35.2784 31.1797 35.9442C31.1797 36.6099 31.7213 37.15 32.3897 37.15H39.5845C40.2529 37.15 40.7945 36.6099 40.7945 35.9442C40.7945 35.2784 40.2529 34.7383 39.5845 34.7383Z'
					fill='#64A1F2'
				/>
				<path
					d='M38.5089 41.25H32.3897C31.7213 41.25 31.1797 41.7901 31.1797 42.4559C31.1797 43.1216 31.7213 43.6618 32.3897 43.6618H38.5089C39.1773 43.6618 39.7188 43.1216 39.7188 42.4559C39.7188 41.7901 39.1773 41.25 38.5089 41.25Z'
					fill='#64A1F2'
				/>
				<path
					d='M24.9496 31.7053C25.1769 31.9314 25.4845 32.0586 25.8055 32.0586C26.1265 32.0586 26.4341 31.9314 26.661 31.7053L29.5118 28.8641C29.9845 28.3931 29.9845 27.63 29.5118 27.1589C29.0392 26.6879 28.2735 26.6879 27.8009 27.1589L25.8055 29.1475L25.1186 28.4622C24.6459 27.9911 23.8799 27.9911 23.4072 28.4622C22.9346 28.9332 22.9346 29.6963 23.4072 30.1674L24.9496 31.7053Z'
					fill='#64A1F2'
				/>
				<path
					d='M23.4072 36.6791L24.9496 38.217C25.1769 38.4431 25.4845 38.5703 25.8055 38.5703C26.1265 38.5703 26.4341 38.4431 26.661 38.217L29.5118 35.3758C29.9845 34.9048 29.9845 34.1417 29.5118 33.6707C29.0392 33.1996 28.2735 33.1996 27.8009 33.6707L25.8055 35.6593L25.1186 34.9739C24.6459 34.5028 23.8799 34.5028 23.4072 34.9739C22.9346 35.4449 22.9346 36.208 23.4072 36.6791Z'
					fill='#64A1F2'
				/>
				<path
					d='M23.4072 43.1916L24.9496 44.7296C25.1769 44.9556 25.4845 45.0828 25.8055 45.0828C26.1265 45.0828 26.4341 44.9556 26.6614 44.7296L29.5122 41.8876C29.9845 41.4165 29.9845 40.6526 29.5118 40.1824C29.0392 39.7113 28.2731 39.7113 27.8005 40.1824L25.8055 42.171L25.1186 41.4864C24.6459 41.0153 23.8799 41.0153 23.4072 41.4864C22.9346 41.9574 22.9346 42.7205 23.4072 43.1916Z'
					fill='#64A1F2'
				/>
			</g>
			<defs>
				<clipPath id='clip0_172_127'>
					<rect width='39' height='34' fill='white' transform='translate(17 21)' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Find;
