import React from "react";

export function ListStyleArtistIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52081 4.14585C8.29249 4.08427 9.02508 3.78082 9.61428 3.2787C10.9892 2.10697 13.0115 2.10697 14.3865 3.2787C14.9757 3.78082 15.7083 4.08427 16.48 4.14585C18.2808 4.28955 19.7107 5.71953 19.8544 7.52032C19.916 8.292 20.2195 9.02459 20.7216 9.61379C21.8933 10.9888 21.8933 13.011 20.7216 14.386C20.2195 14.9752 19.916 15.7078 19.8544 16.4795C19.7107 18.2803 18.2808 19.7103 16.48 19.854C15.7083 19.9155 14.9757 20.219 14.3865 20.7211C13.0115 21.8928 10.9892 21.8928 9.61428 20.7211C9.02508 20.219 8.29249 19.9155 7.52081 19.854C5.72002 19.7103 4.29004 18.2803 4.14634 16.4795C4.08476 15.7078 3.78131 14.9752 3.27919 14.386C2.10746 13.011 2.10746 10.9888 3.27919 9.61379C3.78131 9.02459 4.08476 8.292 4.14634 7.52032C4.29004 5.71953 5.72002 4.28955 7.52081 4.14585ZM16.4489 10.4484C16.9175 9.9798 16.9175 9.22 16.4489 8.75137C15.9803 8.28275 15.2205 8.28275 14.7519 8.75137L10.8004 12.7028L9.24892 11.1514C8.78029 10.6827 8.02049 10.6827 7.55186 11.1514C7.08323 11.62 7.08323 12.3798 7.55186 12.8484L9.95186 15.2484C10.4205 15.7171 11.1803 15.7171 11.6489 15.2484L16.4489 10.4484Z"
        fill="#FF6A3D"
      />
    </svg>
  );
}
