import React from 'react';

const Calendar = () => {
	return (
		<svg
			width='72'
			height='72'
			viewBox='0 0 72 72'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<circle cx='36' cy='36' r='36' fill='#E17596' fillOpacity='0.15' />
			<g clipPath='url(#clip0_158_29)'>
				<path
					d='M22.4758 45.6918C21.7202 45.6918 21.0991 45.0864 21.0991 44.3509V27.1855H47.956V37.3691C49.0646 37.6109 50.1079 38.0209 51.0541 38.57V22.6609C51.0541 20.4082 49.1608 18.5645 46.8463 18.5645H45.9375V20.2964C45.9375 22.1491 44.388 23.6664 42.4845 23.6664H40.3614C38.4579 23.6664 36.9083 22.1491 36.9083 20.2964V18.5645H32.1458V20.2964C32.1458 22.1491 30.5962 23.6664 28.6833 23.6664H26.5696C24.6661 23.6664 23.1166 22.1491 23.1166 20.2964V18.5645H22.4758C20.0082 18.5645 18 20.52 18 22.9127V44.3509C18 46.7527 20.0082 48.7082 22.4758 48.7082H34.6321C34.5938 48.4009 34.5845 48.0836 34.5845 47.7673C34.5845 47.06 34.6517 46.3618 34.7946 45.6918H22.4758Z'
					fill='#E17596'
				/>
				<path
					d='M28.691 29.65H24.4113C24.1178 29.65 23.8799 29.8816 23.8799 30.1673V32.4591C23.8799 32.7448 24.1178 32.9763 24.4113 32.9763H28.691C28.9845 32.9763 29.2225 32.7448 29.2225 32.4591V30.1673C29.2225 29.8816 28.9845 29.65 28.691 29.65Z'
					fill='#E17596'
				/>
				<path
					d='M28.691 39.8982H24.4113C24.1181 39.8982 23.8799 40.13 23.8799 40.4164V42.7082C23.8799 42.9936 24.1181 43.2255 24.4113 43.2255H28.691C28.9843 43.2255 29.2225 42.9936 29.2225 42.7082V40.4164C29.2225 40.13 28.9843 39.8982 28.691 39.8982Z'
					fill='#E17596'
				/>
				<path
					d='M40.3664 32.9763H44.6452C44.9394 32.9763 45.1776 32.7454 45.1776 32.4591V30.1673C45.1776 29.8818 44.9394 29.65 44.6452 29.65H40.3664C40.0731 29.65 39.835 29.8818 39.835 30.1673V32.4591C39.835 32.7454 40.0731 32.9763 40.3664 32.9763Z'
					fill='#E17596'
				/>
				<path
					d='M37.2 30.1673C37.2 29.8818 36.9618 29.65 36.6676 29.65H32.3889C32.0956 29.65 31.8574 29.8818 31.8574 30.1673V32.4591C31.8574 32.7454 32.0956 32.9763 32.3889 32.9763H36.6676C36.9618 32.9763 37.2 32.7454 37.2 32.4591V30.1673Z'
					fill='#E17596'
				/>
				<path
					d='M37.1964 40.4136C37.1964 40.1345 36.9666 39.9018 36.6696 39.9018H32.3852C32.0985 39.9018 31.8594 40.1345 31.8594 40.4136V42.7127C31.8594 42.9918 32.0985 43.2245 32.3852 43.2245H35.6272C36.0391 42.3682 36.565 41.5773 37.1964 40.8691V40.4136Z'
					fill='#E17596'
				/>
				<path
					d='M28.691 34.7745H24.4113C24.1178 34.7745 23.8799 35.0061 23.8799 35.2918V37.5836C23.8799 37.8693 24.1178 38.1009 24.4113 38.1009H28.691C28.9845 38.1009 29.2225 37.8693 29.2225 37.5836V35.2918C29.2225 35.0061 28.9845 34.7745 28.691 34.7745Z'
					fill='#E17596'
				/>
				<path
					d='M40.3618 38.1045H40.9166C42.2177 37.5091 43.6617 37.1645 45.1823 37.1182V35.2936C45.1823 35.0045 44.9432 34.7718 44.6471 34.7718H40.3618C40.0751 34.7718 39.8359 35.0045 39.8359 35.2936V37.5836C39.8359 37.8718 40.0751 38.1045 40.3618 38.1045Z'
					fill='#E17596'
				/>
				<path
					d='M36.6676 34.7745H32.3889C32.0956 34.7745 31.8574 35.0055 31.8574 35.2918V37.5836C31.8574 37.8691 32.0956 38.1009 32.3889 38.1009H36.6676C36.9618 38.1009 37.2 37.8691 37.2 37.5836V35.2918C37.2 35.0055 36.9618 34.7745 36.6676 34.7745Z'
					fill='#E17596'
				/>
				<path
					d='M26.5722 21.3827H28.6915C29.3079 21.3827 29.8076 20.8964 29.8076 20.2964V16.0873C29.8076 15.4864 29.3079 15 28.6915 15H26.5722C25.9557 15 25.4551 15.4864 25.4551 16.0873V20.2964C25.4551 20.8964 25.9557 21.3827 26.5722 21.3827Z'
					fill='#E17596'
				/>
				<path
					d='M40.3652 21.3827H42.4845C43.1009 21.3827 43.6006 20.8964 43.6006 20.2964V16.0873C43.6006 15.4864 43.1009 15 42.4845 15H40.3652C39.7487 15 39.249 15.4864 39.249 16.0873V20.2964C39.249 20.8964 39.7487 21.3827 40.3652 21.3827Z'
					fill='#E17596'
				/>
				<path
					d='M45.5374 39.5273C40.8636 39.5273 37.0752 43.2145 37.0752 47.7636C37.0752 52.3127 40.8636 56 45.5374 56C50.2103 56 53.9997 52.3127 53.9997 47.7636C53.9997 43.2145 50.2103 39.5273 45.5374 39.5273ZM48.9952 48.9736H45.5374C44.8509 48.9736 44.2942 48.4318 44.2942 47.7636V43.1918C44.2942 42.5236 44.8509 41.9818 45.5374 41.9818C46.2239 41.9818 46.7806 42.5236 46.7806 43.1918V46.5536H48.9952C49.6817 46.5536 50.2384 47.0954 50.2384 47.7636C50.2384 48.4318 49.6817 48.9736 48.9952 48.9736Z'
					fill='#E17596'
				/>
			</g>
			<defs>
				<clipPath id='clip0_158_29'>
					<rect width='36' height='41' fill='white' transform='translate(18 15)' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Calendar;
